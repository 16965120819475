import React, {useEffect, useState} from 'react';
import {Field} from 'formik';
import {filter as _filter} from 'lodash';

import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Form, DataTable} from '../components';
import Select from 'react-select';

import {languages, countries} from '../config';

import {Chatbot, User} from '../states/actions';
import {axios} from '../libs';

export const Chatbots = () => {
  const [refetch, setrefetch] = useState(false);
  const {push} = useHistory();
  const {chatbotId} = useParams();
  const {blocks, ...data} = useSelector(Chatbot.get());

  useEffect(() => {
    if (chatbotId) {
      (async () => {
        const {blocks, ...data} = await Chatbot.fetch(chatbotId);
        Chatbot.set(data);
      })();
    } else {
      Chatbot.clear();
    }
  }, [chatbotId]);

  const onSuccess = ({chatbot}) => {
    setrefetch(false);
    Chatbot.set(chatbot);
    if (chatbotId) {
      push('/chatbots');
      setrefetch(true);
    } else {
      push(`/chatbot-builder/${chatbot._id}`);
    }
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (value) => {
        return (
          <span className="badge bg-primary" role="button">
            {value}
          </span>
        );
      },
    },
    {
      dataField: 'user',
      text: 'Created By',
      formatter: ({firstName, lastName}) => {
        return `${firstName} ${lastName}`;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last modified',
      formatter: (cell) => {
        return cell.split('T')[0];
      },
    },
  ];

  const submissionsColumns = [
    {
      dataField: '_id',
      text: 'S.n',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      style: {
        width: 10,
      },
    },
    {
      dataField: 'chatbot.name',
      text: 'Name',
    },
    {
      dataField: 'chatbot.description',
      text: 'Description',
      style: {
        width: '50%',
      },
    },
    {
      dataField: 'user',
      text: 'Created By',
      formatter: ({firstName, lastName}) => {
        return `${firstName} ${lastName}`;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last modified',
      formatter: (cell) => {
        return cell.split('T')[0];
      },
    },
  ];

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this chatbot?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteChatbot(_id);
      },
    });
  };

  const deleteChatbot = async (id) => {
    await axios.delete(`/chatbot/${id}`);
    setrefetch(true);
  };

  const onEdit = (data) => {
    push(`chatbots/${data._id}`);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>
                Chatbot <b>{data.name}</b>
              </h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form usePUT={chatbotId ? true : false} url="/chatbot" initialValues={data} onSuccess={onSuccess}>
                <div className="row mb-3">
                  <label htmlFor="name" className="col-sm-3 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-9">
                    <Field
                      as="input"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Employment Contract"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="description" className="col-sm-3 col-form-label">
                    Description
                  </label>
                  <div className="col-sm-9">
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      className="form-control"
                      placeholder="This bot deals with all the required employment contracts"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="primaryLang" className="col-sm-3 col-form-label">
                    Primary Language
                  </label>
                  <div className="col-md-9">
                    <Field name="primaryLang">
                      {({field, form}) => (
                        <Select
                          id="primaryLang"
                          name={field.name}
                          options={languages}
                          value={languages.find((item) => item.value === form.values.primaryLang)}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                          isDisabled={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="lang" className="col-sm-3 col-form-label">
                    Languages
                  </label>
                  <div className="col-md-9">
                    <Field name="lang">
                      {({field, form}) => (
                        <Select
                          id="lang"
                          name={field.name}
                          options={languages.filter((i) => i.value !== form.values.primaryLang)}
                          value={_filter(languages, (item) => form.values.lang.indexOf(item.value) >= 0)}
                          closeMenuOnSelect={false}
                          isMulti={true}
                          onChange={(options) => {
                            form.setFieldValue(
                              field.name,
                              options.map((v) => v.value),
                            );
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="country" className="col-sm-3 col-form-label">
                    Countries
                  </label>
                  <div className="col-md-9">
                    <Field name="country">
                      {({field, form}) => (
                        <Select
                          id="country"
                          name={field.name}
                          options={countries}
                          value={_filter(countries, (item) => form.values.country?.indexOf(item.value) >= 0)}
                          closeMenuOnSelect={false}
                          isMulti={true}
                          onChange={(options) => {
                            form.setFieldValue(
                              field.name,
                              options.map((v) => v.value),
                            );
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                        />
                      )}
                    </Field>
                    <div className="form-text">Leave blank for global</div>
                  </div>
                </div>
                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9  offset-md-3">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Chatbots</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <DataTable
                refetch={refetch}
                navigateTo="/chatbot-builder"
                url="/chatbot"
                dataKey="chatbots"
                tableHeading="Chatbots"
                columns={tableColumns}
                onDelete={onDelete}
                onEdit={onEdit}
                isPreview={User.isAdmin()}
              />
            </div>
          </div>
        </div>
      </div>
      {User.isAdmin() && (
        <div className="row">
          <div className="col">
            <div className="x_panel">
              <div className="x_title">
                <h2>Chatbot Subimissions</h2>
                <div className="clearfix"></div>
              </div>
              <div className="x_content">
                <DataTable
                  navigateTo="/chatbot-review"
                  url="/chatbot/submissions"
                  dataKey="chatbots"
                  tableHeading="Chatbots"
                  columns={submissionsColumns}
                  onDelete={() => {}}
                  onEdit={onEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
