import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {onRemove, onEdit, onSetStart, onClose, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';

export const DataBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());

  const refTxtProperty = useRef(null);
  const refTxtValue = useRef(null);

  if (!block) {
    return null;
  }
  const {
    editMode = false,
    data: {data = {}},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = block.data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  const addDataField = (e) => {
    e.preventDefault();
    const property = refTxtProperty.current.value;
    const value = refTxtValue.current.value;
    if (property !== '') {
      Chatbot.setBlockData(node.id, {data: {[property]: value}});
      refTxtProperty.current.value = '';
      refTxtValue.current.value = '';
    }
  };

  const removeDataField = (e, key) => {
    e.preventDefault();
    Chatbot.removeDataBlock(node.id, key);
  };

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-database"></i> Data
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <label htmlFor="data" className="d-none">
              Data
            </label>
            <table id="data" className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Property</th>
                  <th scope="col" colSpan="2">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data).map(([key, value], i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <input
                          type="text"
                          name="property"
                          className="form-control"
                          placeholder="property"
                          defaultValue={key}
                          onBlur={(e) => Chatbot.setBlockData(node.id, {data: {[e.target.value]: value}})}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="value"
                          className="form-control"
                          placeholder="value"
                          defaultValue={value}
                          onBlur={(e) => Chatbot.setBlockData(node.id, {data: {[key]: e.target.value}})}
                        />
                      </td>
                      <td>
                        <span
                          className="input-group-text btn btn-outline-danger border-0"
                          onClick={(e) => removeDataField(e, key)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    <input
                      type="text"
                      id="txtProperty"
                      className="form-control"
                      placeholder="property"
                      ref={refTxtProperty}
                      onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="txtValue"
                      className="form-control"
                      placeholder="value"
                      ref={refTxtValue}
                      onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                      onBlur={(e) => addDataField(e)}
                    />
                  </td>
                  <td>
                    <span
                      className="input-group-text btn btn-outline-success border-0"
                      onClick={(e) => addDataField(e)}
                    >
                      <i className="fa fa-plus"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node decision-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {data.content}
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>
          <br />
          {Object.entries(data).map(([key, value], i) => (
            <div key={i} className="mb-3 position-relative">
              {key} : {value}
            </div>
          ))}
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="target" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
};
