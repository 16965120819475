import React from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import {Chatbot} from '../../states/actions';
import {onRemove, onEdit, onSetStart, onClose, isLightColor} from '../../helpers/block-helper';
import {opertors} from '../../config';
import Select from 'react-select';
import {ColorPicker} from '../ColorPicker';

export const ConditionBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  if (!block) {
    return null;
  }
  const {data, editMode = false} = block;

  const variableOptions = Chatbot.getBlockVariables();
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block active nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-exchange-alt"></i> Condition
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                onClick={() => onClose(block.id)}
                className="ms-1 me-2 icon fa fa-times"
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="input-group mb-0">
              <label htmlFor="variable" className="form-label d-none">
                Variable
              </label>
              <Select
                placeholder="Variables"
                id="variable"
                name="variable"
                options={variableOptions}
                value={variableOptions.find((item) => item.value === data.variable)}
                className="form-control form-control-sm react-select-container"
                classNamePrefix="react-select"
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {variable: option.value});
                }}
              />
              <label htmlFor="value" className="form-label d-none">
                Value
              </label>
              <input
                type="input"
                id="value"
                name="value"
                className="form-control"
                placeholder="Value"
                defaultValue={data.value}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <div className="input-group mb-3">
              <Handle id="false" className="block-handle block-handle-left" type="source" position="left" />
              <span className="input-group-text">False</span>
              <label htmlFor="operator" className="form-label d-none">
                Operator
              </label>
              <Select
                placeholder="Select Operators"
                id="operator"
                name="operator"
                options={opertors}
                value={opertors.find((item) => item.value === data.operator)}
                className="form-control form-control-sm react-select-container"
                classNamePrefix="react-select"
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {operator: option.value});
                }}
              />
              <span className="input-group-text">True</span>
              <Handle id="true" className="block-handle block-handle-right" type="source" position="right" />
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          <br />
          <div className="mb-3 position-relative">
            <span className="fw-bolder left">F</span> {data.variable} {data.operator} {data.value}{' '}
            <span className="fw-bolder right">T</span>
            <Handle id="true" className="block-handle block-handle-right" type="source" position="right" />
            <Handle id="false" className="block-handle block-handle-left" type="source" position="left" />
          </div>

          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="target" className="block-handle" type="target" position="top" />
    </>
  );
};
