import {
  TextBlock,
  InputBlock,
  ConditionBlock,
  DecisionBlock,
  TeleportBlock,
  SendyBlock,
  RedirectBlock,
  DocumentBlock,
  DataBlock,
  RichTextBlock,
  BookingBlock,
  EmailBlock,
  PipedriveBlock,
  ScoroBlock,
  UploadBlock,
  MultipleChoiceBlock,
  DieselgateBlock,
  GTMBlock,
} from './';

export const nodeTypes = {
  textBlock: TextBlock,
  inputBlock: InputBlock,
  conditionBlock: ConditionBlock,
  decisionBlock: DecisionBlock,
  teleportBlock: TeleportBlock,
  sendyBlock: SendyBlock,
  redirectBlock: RedirectBlock,
  documentBlock: DocumentBlock,
  dataBlock: DataBlock,
  richTextBlock: RichTextBlock,
  bookingBlock: BookingBlock,
  emailBlock: EmailBlock,
  pipedriveBlock: PipedriveBlock,
  scoroBlock: ScoroBlock,
  uploadBlock: UploadBlock,
  multipleChoiceBlock: MultipleChoiceBlock,
  dieselgateBlock: DieselgateBlock,
  gtmBlock: GTMBlock,
};
