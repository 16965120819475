import React from 'react';
import {useSelector} from 'react-redux';
import {Route} from 'react-router';
import {Sidebar} from '../../components';
import {User} from '../../states/actions';
import {
  Dashboard,
  Users,
  Chatbots,
  ChatbotBuilder,
  Documents,
  DocumentEdit,
  EmailContents,
  ChatbotReview,
  EmailTemplates,
  Conversation,
} from '../index';

export default function MainLayout() {
  const user = useSelector(User.get());
  const pages = [
    {
      path: '/',
      component: Dashboard,
      roles: ['admin', 'lawyer'],
    },
    {
      path: '/users',
      component: Users,
      roles: ['admin'],
    },
    {
      path: '/users/:userId',
      component: Users,
      roles: ['admin'],
    },
    {
      path: '/email-contents',
      component: EmailContents,
      roles: ['admin'],
    },
    {
      path: '/email-contents/:emailContentId',
      component: EmailContents,
      roles: ['admin'],
    },
    {
      path: '/chatbots/:chatbotId?',
      component: Chatbots,
      roles: ['admin', 'lawyer'],
    },
    {
      path: '/chatbot-review/:submissionId',
      component: ChatbotReview,
      roles: ['admin', 'lawyer'],
    },
    {
      path: '/chatbot-builder/:chatbotId/:redirectId?',
      component: ChatbotBuilder,
      roles: ['admin', 'lawyer'],
    },

    {
      path: '/documents',
      component: Documents,
      roles: ['admin', 'lawyer'],
    },
    {
      path: '/user/document/:documentId',
      component: DocumentEdit,
      roles: ['admin', 'lawyer'],
    },
    {
      path: '/email-templates',
      component: EmailTemplates,
      roles: ['admin'],
    },
    {
      path: '/email-templates/:emailTemplateId',
      component: EmailTemplates,
      roles: ['admin'],
    },
    {
      path: '/chatbot/conversation/:chatbotId',
      component: Conversation,
      roles: ['admin'],
    },
  ];

  return (
    <>
      <div className="container body">
        <div className="main_container">
          <Sidebar />
          <div className="right_col" role="main">
            {pages.map((page, index) => {
              if (page.roles.includes(user.role)) {
                return <Route key={index} exact path={page.path} component={page.component} />;
              } else {
                return (
                  <Route
                    key={index}
                    exact
                    path={page.path}
                    children={<h2>You are not authorized to view this page</h2>}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}
