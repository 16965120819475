import React, {useEffect, useState} from 'react';
import {DataTable, Form} from '../components';
import {Field} from 'formik';
import Select from 'react-select';
import {useHistory, useParams} from 'react-router';
import {axios} from '../libs';
import {roles} from '../config';
import {User} from '../states/actions';

export const Users = () => {
  const [refetch, setrefetch] = useState(false);
  const [initialValues, setinitialValues] = useState({email: '', role: ''});
  const {push} = useHistory();
  const {userId} = useParams();

  useEffect(() => {
    if (userId) {
      (async () => {
        const {user} = await axios.get(`/user/${userId}`);
        setinitialValues({
          _id: userId,
          email: user.email,
          role: user.role,
        });
      })();
    }
  }, [userId]);

  const tableColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'role',
      text: 'Role',
    },
  ];

  const userRequestColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'request',
      text: 'Request Message',
    },
    {
      dataField: 'createdAt',
      text: 'Requested At',
      formatter: (date) => {
        return date.split('T')[0];
      },
    },
  ];

  const onSuccess = () => {
    setrefetch(false);
    if (userId) {
      setrefetch(true);
      push('/users');
    } else {
      setrefetch(true);
    }
  };

  const onEdit = ({_id}) => {
    push(`/users/${_id}`);
  };

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this user?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteUser(_id);
      },
    });
  };

  const deleteUser = async (id) => {
    await axios.delete(`/user/${id}`);
    setrefetch(true);
  };

  const actions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="row row-cols-auto">
        <h6>
          <span
            onClick={() => {
              console.log('Accept');
            }}
            className="mx-1 badge bg-primary"
          >
            Accept
          </span>
        </h6>
        <h6>
          <span
            onClick={() => {
              console.log('Reject');
            }}
            className="mx-1 badge bg-danger"
          >
            Reject
          </span>
        </h6>

        {/* <i onClick={() => onEdit(row)} className="col edit-icon fas fa-pencil-alt fa-lg"></i> */}
      </div>
    );
  };

  const userRequestActions = {
    dataField: '',
    text: 'Actions',
    formatter: actions,
    style: {
      width: 200,
    },
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>New User</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form url="/user" usePUT={userId ? true : false} initialValues={initialValues} onSuccess={onSuccess}>
                <div className="item form-group row">
                  <label htmlFor="email" className="control-label col-md-3 col-sm-3 ">
                    Email
                  </label>
                  <div className="innerContainer col-md-9 col-sm-9 ">
                    <Field id="email" name="email" type="text" className="form-control" autoComplete="off" />
                  </div>
                </div>
                <div className="item form-group row">
                  <label htmlFor="role" className="control-label col-md-3 col-sm-3 ">
                    Role
                  </label>
                  <div className="innerContainer col-md-9 col-sm-9 ">
                    <Field id="role" name="role">
                      {({field, form}) => (
                        <Select
                          id="role"
                          name={field.name}
                          value={roles.find((i) => i.value === form.values.role)}
                          options={roles}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9  offset-md-3">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Users</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <DataTable
                refetch={refetch}
                onDelete={onDelete}
                onEdit={onEdit}
                url="/user/all"
                dataKey="users"
                tableHeading="Users"
                columns={tableColumns}
              />
            </div>
          </div>
        </div>
      </div>
      {User.isAdmin() && (
        <div className="row">
          <div className="col">
            <div className="x_panel">
              <div className="x_title">
                <h2>User Requests</h2>
                <div className="clearfix"></div>
              </div>
              <div className="x_content">
                <DataTable
                  url="/user-request"
                  dataKey="userRequest"
                  columns={userRequestColumns}
                  actions={userRequestActions}
                  // onDelete={() => {}}
                  // onEdit={onEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
