import React, {useEffect, useRef, useState, memo, useCallback} from 'react';
import DatePicker from 'react-datepicker';
import {useDropzone} from 'react-dropzone';
import chatLogo from './assets/chat-logo.png';
import {axios} from './axios';
import Select from 'react-select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {useParams} from 'react-router';
import './Chat.css';
import {User} from '../../../states/actions';
import {useSelector} from 'react-redux';
import {moneyCurrency} from '../../../config';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import {PROGRESS_SEGMENTS, userBlockTypes} from '../../../constant/constant';

import 'react-datepicker/dist/react-datepicker.css';

const {nError} = window;

function Chat() {
  const {chatbotId, redirectId} = useParams();
  const [showChat, setShowChat] = useState(false);
  const [files, setFiles] = useState([]);
  const [waiting, setwaiting] = useState(false);
  const [address, setAddress] = useState(null);
  const [uploadFileError, showUploadFileError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedtime, setSelectedTime] = useState(null);
  const [chatProgress, setChatProgress] = useState({total: 0, current: 0, ratio: 0});
  const [currentProgress, setCurrentProgress] = useState(1);
  const [progressToShow, setProgressToShow] = useState();

  const inputRef = useRef(null);
  const optionSelectRef = useRef(null);
  const {_id} = useSelector(User.get());

  const [block, setBlock] = useState({});
  const [messages, setmessages] = useState([]);

  const {getRootProps, getInputProps} = useDropzone({
    accept: block?.acceptedTypes?.toString(),
    onDropRejected: (rejectedFiles) => {
      const {errors} = rejectedFiles[0];
      showUploadFileError(errors[0]);
    },
    onDrop: (acceptedFiles) => {
      const updatedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      showUploadFileError(null);
      setFiles([...files, ...updatedFiles]);
      _scroll();
    },
  });

  useEffect(() => {
    const chat = async () => {
      await callApi();
    };
    chat();
  }, []);

  useEffect(() => {
    const updateChat = async () => {
      try {
        if (redirectId) {
          await axios.put(`/redirect/${redirectId}`);
          window.close(); // Close window for n
        }
      } catch (error) {
        console.error(error);
      }
    };
    updateChat();
  }, [redirectId]);

  useEffect(() => {
    if (currentProgress === 1) {
      handleChatProgress();
    }
  }, [currentProgress]);

  const onReply = async (event) => {
    event.preventDefault();
    let userReply = '';

    if (block.validation === 'money') {
      userReply = `${moneyCurrency.find((i) => i.value === block.currency).sign} ${inputRef.current.value}`;
    } else if (block.validation === 'address') {
      userReply = address.value.description;
      setAddress(null);
    } else if (block.validation === 'name') {
      userReply = inputRef.current.value
        .trim()
        .split(' ')
        .map((i) => i[0]?.toUpperCase() + i.slice(1)?.toLowerCase())
        .join(' ');
    } else if (block.validation === 'date') {
      const temp = moment(new Date(selectedDate)).format('DD.MM.YYYY');
      userReply = temp;
    } else if (block.validation === 'birthday') {
      const temp = moment(new Date(selectedDate)).format('DD.MM.YYYY');
      userReply = temp;
    } else if (block.validation === 'time') {
      const temp = moment(new Date(selectedtime)).format('HH:mm');
      userReply = temp;
    } else {
      userReply = inputRef.current.value.trim();
    }

    await reply(userReply);
    _scroll();
  };

  const reply = async (userReply, replyText = '') => {
    setMessage({msg: replyText ? replyText : userReply, user: 'user'});
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    await callApi(block.id, userReply);
  };

  const callApi = async (id = undefined, reply = undefined) => {
    try {
      setwaiting(true);
      const _block = await axios.post(chatbotId, {id, reply}, _id);

      if (_block.id) {
        if (userBlockTypes.includes(_block.type) && block.id !== _block.id) {
          handleChatProgress();
        } else if (_block.type === 'text' && _block.next === null) {
          // last
          handleChatProgress();
        }

        setBlock(_block);
        setMessage({msg: _block.msg, type: _block.type});
        if (!_block.waitForReply) {
          // check for gtm
          if (_block.type === 'gtm') {
            gtmEvent(_block);
          }
          await callApi(_block.id);
        }
      } else {
        setBlock({});
      }
      setwaiting(false);
      _scroll();
      return _block;
    } catch (error) {
      console.log(error);
      setwaiting(false);
      nError({title: 'Opps!', text: error.response.data.error, icon: 'fa-exclamation-triangle', timeout: false});
    }
  };

  const onStartAgain = async () => {
    setmessages([]);
    await callApi();
    setChatProgress((prev) => ({
      ...prev,
      current: 1,
    }));
    setCurrentProgress(1);
  };

  const uploadFile = async (id, formData) => {
    setwaiting(true);
    const _block = await axios.post(chatbotId, formData, _id);
    if (_block.id) {
      setBlock(_block);
      setMessage({msg: _block.msg});
      if (!_block.waitForReply) {
        await callApi(_block.id);
      }
    } else {
      setBlock({});
    }
    setwaiting(false);
    _scroll();
  };

  const setMessage = ({user = 'system', ...rest}) => {
    setmessages((state) => {
      return [...state, {user, ...rest}];
    });
  };

  const fetchChatProgress = async () => {
    const path = `/progress/${chatbotId}`;
    const {progressCount} = await axios.get(path);
    const ratio = Math.floor(progressCount / PROGRESS_SEGMENTS) > 1 ? Math.floor(progressCount / PROGRESS_SEGMENTS) : 1;
    setChatProgress((prev) => ({
      ...prev,
      total: progressCount,
      ratio,
    }));
  };

  const handleShowChat = () => {
    if (!showChat) {
      fetchChatProgress();
    }

    setShowChat(!showChat);
  };

  const _scroll = () => {
    const chatBody = document.getElementById('chat-body');
    if (chatBody) {
      chatBody.scrollTop = chatBody?.scrollHeight;
    }
  };

  const gtmEvent = (block) => {
    if (window.dataLayer) {
      const {event, variables = []} = block;
      const obj = {event};
      for (const {name, value} of variables) {
        obj[name] = value;
      }
      window.datalayer.push(obj);
    } else {
      console.log('data layer not available');
    }
  };

  const thumbs = files.map((file) => {
    const isImage = file.type.split('/')[0] === 'image';
    return (
      <div className="col-3 position-relative widget-image-thumb my-1" key={file.name}>
        {isImage && <img src={file.preview} className="img" alt={file.name} />}
        {!isImage && (
          <div className="row align-items-center">
            <div className="col-md-3 offset-md-3">
              <i className="fa fa-file-text-o fa-3x"></i>
            </div>
            <div className="col offset-md-1">{file.name}</div>
          </div>
        )}
        <button
          id="image-delete-button"
          onClick={() => {
            setFiles(files.filter((i) => i.preview !== file.preview));
          }}
          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          <i className="fa fa-trash" style={{color: '#fff'}}></i>
        </button>
      </div>
    );
  });

  const handleChatProgress = () => {
    if (chatProgress.current / chatProgress.ratio >= 1) {
      setChatProgress((prev) => ({
        ...prev,
        current: 1,
      }));
      setCurrentProgress(currentProgress + 1);
    } else {
      setChatProgress((prev) => ({
        ...prev,
        current: prev.current + 1,
      }));
    }

    const p_ = (100 / PROGRESS_SEGMENTS) * currentProgress > 100 ? 100 : (100 / PROGRESS_SEGMENTS) * currentProgress;

    setProgressToShow(p_);
  };

  return (
    <>
      <div onClick={handleShowChat} className="chat-opener"></div>
      {showChat && (
        <div className="chat-wrapper">
          <div className="chat-header">
            <img src="/images/hugo.legal.png" alt="Hugo legal logo" className="img-fluid" width="100" />
            {/* <p>HUGO CHAT</p> */}
            <div className="mx-2 header-options">
              <div className="pr-1">
                <i className="mx-3 fa fa-sync fa-lg" title="Start Again" onClick={onStartAgain}></i>
                <i onClick={() => setShowChat(false)} alt="close-icon" className="mx-2 fas fa-times fa-lg"></i>
              </div>
            </div>
          </div>

          {/* progress bar */}
          <div>
            <span>Current Progress: {progressToShow}%</span>
            <span>{progressToShow > 70 && 'Almost Completed'}</span>
          </div>

          <div id="chat-body" className="chat-body">
            {messages?.map((message, index) => {
              return message.user === 'user' ? (
                <div key={index} className="message-wrapper-user">
                  <div className="message mx-2">{message.msg}</div>
                </div>
              ) : (
                message.msg && (
                  <div key={index}>
                    <div className="message-wrapper">
                      <div className="message-wrapper-row">
                        <img src={chatLogo} alt="avatar" className="img-fluid" width="25" height="auto" />
                        <div className="message mx-2" dangerouslySetInnerHTML={{__html: message.msg}}></div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}

            {block.type === 'decision' && (
              <div id={block.id} className="mx-5">
                {block?.decisions
                  ?.map((item) => (
                    <span
                      key={item.id}
                      className="btn btn-outline-primary mx-2 btn-sm"
                      onClick={async () => {
                        _scroll();
                        reply(item.text);
                      }}
                    >
                      {item.text}
                    </span>
                  ))
                  .reverse()}
              </div>
            )}
            {block.type === 'booking' && (
              <div id="message-wrapper-booking">
                {block?.types?.map((item, i) => (
                  <span
                    key={i}
                    className="btn btn-outline-primary btn-sm"
                    onClick={(ev) => {
                      reply(item);
                    }}
                  >
                    {item}
                  </span>
                ))}

                {block?.timeSlots?.map((slot) => (
                  <span
                    key={slot.id}
                    className="btn btn-outline-primary mx-2 btn-sm"
                    onClick={(ev) => {
                      reply(slot.id, ev.target.innerText);
                    }}
                  >
                    {slot.date} - {slot.start_time}-{slot.end_time}
                  </span>
                ))}
              </div>
            )}
            {block.type === 'multipleChoice' && (
              <div id="multiple-choice">
                <div className="row mx-4">
                  <div className="col-10">
                    <Select
                      options={block.options}
                      ref={optionSelectRef}
                      id="choice"
                      name="choice"
                      placeholder={block?.hint ? block.hint : 'Select Option'}
                      classNamePrefix="react-select"
                      menuPlacement="auto"
                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                          const selectedOption = optionSelectRef.current.state.value;
                          if (selectedOption) {
                            reply(selectedOption.label);
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <button
                      onClick={async () => {
                        const selectedOption = optionSelectRef.current.state.value;
                        if (selectedOption) {
                          reply(selectedOption.label);
                        }
                      }}
                      className="btn btn-primary btn-sm mt-1"
                    >
                      <i className="fa fa-share"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {block.type === 'upload' && (
              <div id="upload-container">
                <div className="container">
                  <div className="row mx-2">
                    <div className="file-drop-zone col" {...getRootProps()}>
                      <input {...getInputProps({multiple: true})} />
                      <p>{`click to select files only these files are allowed (${block?.acceptedTypes?.toString()}) `}</p>
                      {uploadFileError?.message && <p className="file-drop-zone__error">{uploadFileError.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mx-2">{thumbs}</div>
                <div className="row mx-2">
                  <div className="col">
                    {files.length > 0 && (
                      <button
                        id="upload-submit-button"
                        onClick={async () => {
                          const formData = new FormData();
                          formData.append('id', block.id);
                          files.forEach((file) => {
                            formData.append('files', file);
                          });
                          await uploadFile(block.id, formData);
                          setFiles([]);
                        }}
                        className="btn btn-primary btn-sm my-2 ml-4"
                      >
                        <i className="fa fa-share"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {block.type === 'input' && (
              <div className="chat-footer">
                <form onSubmit={onReply}>
                  {block.validation === 'money' && block.currency ? (
                    <div className="input-group">
                      <span className="input-group-text">
                        {moneyCurrency.find((i) => i.value === block.currency).sign}
                      </span>
                      <input
                        ref={inputRef}
                        type="text"
                        className="form-control border-end-0"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder={block?.hint ? block.hint : '00.00'}
                        required
                      />
                    </div>
                  ) : block.validation === 'time' ? (
                    <div>
                      <div className="row">
                        <div className="col-10">
                          <DatePicker
                            selected={selectedtime}
                            ref={inputRef}
                            onChange={(time) => {
                              setSelectedTime(time);
                            }}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            id="time"
                            name="time"
                            placeholderText={block?.hint ? block.hint : 'HH:MM'}
                            className="form-control border-end-0"
                            isClearable={true}
                            required={true}
                            showTimeSelect
                            showTimeSelectOnly
                          />
                        </div>
                        <div className="col-2">
                          <button
                            onClick={(e) => {
                              if (selectedtime) {
                                onReply(e);
                              }
                            }}
                            className="btn btn-primary btn-sm mt-1"
                          >
                            <i className="fa fa-share"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : block.validation === 'date' || block.validation === 'birthday' ? (
                    <div>
                      <div className="row">
                        <div className="col-10">
                          <DatePicker
                            customInput={
                              <MaskedInput mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />
                            }
                            selected={selectedDate}
                            ref={inputRef}
                            onChange={(date) => {
                              setSelectedDate(date);
                            }}
                            dateFormat="dd.MM.yyyy"
                            id="date"
                            name="date"
                            placeholderText={block?.hint ? block.hint : 'DD.MM.YYYY'}
                            className="form-control border-end-0"
                            isClearable={true}
                            required={true}
                          />
                        </div>
                        <div className="col-2">
                          <button
                            onClick={(e) => {
                              if (selectedDate) {
                                onReply(e);
                              }
                            }}
                            className="btn btn-primary btn-sm mt-1"
                          >
                            <i className="fa fa-share"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : block.validation === 'address' ? (
                    <div className="input-group">
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_AUTO_COMPLETE}
                        selectProps={{
                          onChange: setAddress,
                          value: address,
                          placeholder: block?.hint ? block.hint : 'Please enter your address',
                          className: 'google-autocomplete-input',
                          name: 'address',
                          id: 'address',
                        }}
                      />
                    </div>
                  ) : (
                    <div className="input-group">
                      <input
                        ref={inputRef}
                        as="input"
                        id="reply"
                        name="reply"
                        className="form-control border-end-0"
                        placeholder={block?.hint ? block.hint : 'Please enter your text here'}
                        required
                      />
                    </div>
                  )}
                </form>
              </div>
            )}

            {waiting && (
              <div key="indicator" className="message-wrapper">
                <div className="message-wrapper-row">
                  <img src={chatLogo} alt="avatar" className="img-fluid" width="25" height="auto" />
                  <div className="message mx-2">
                    <div id="typing-indicator" className="" style={{display: 'block'}}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default memo(Chat);
