import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {onRemove, onEdit, onSetStart, onClose, isLightColor} from '../../helpers/block-helper';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorDecisionBlock} from '../../config';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';

export const DecisionBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());

  const refTxtText = useRef(null);
  const refTxtValue = useRef(null);

  if (!block) {
    return null;
  }
  const {
    data,
    editMode = false,
    data: {decisions = {}},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  const addDecisionField = (e) => {
    e.preventDefault();
    const id = Date.now().toString();
    const text = refTxtText.current.value;
    const value = refTxtValue.current.value;
    if (text !== '') {
      Chatbot.setBlockData(node.id, {decisions: {[id]: {id, text, value}}});
      refTxtText.current.value = '';
      refTxtValue.current.value = '';
    }
  };

  const removeDecisionField = (e, id) => {
    e.preventDefault();
    Chatbot.removeBlockDecision(node.id, id);
  };

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="far fa-list-alt"></i> Decision
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                onClick={() => onClose(block.id)}
                className="ms-1 me-2 icon fa fa-times"
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-2">
              <label htmlFor="content" className="d-none">
                Content
              </label>
              <div id="content">
                <CKEditor
                  config={{
                    ...ckEditorDecisionBlock,
                    simpleUpload: {
                      uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                    },
                  }}
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="variable" className="form-label">
                Variable (to store the answer)
              </label>
              <input
                type="input"
                id="variable"
                name="variable"
                className="form-control"
                placeholder="Variable Name"
                defaultValue={data.variable}
                onBlur={(e) => Chatbot.setBlockData(node.id, {[e.target.name]: e.target.value})}
              />
            </div>

            <div className="mb-2 d-flex align-items-center">
              <label htmlFor="dateTime" className="form-label">
                Record Date and Time
              </label>
              <input
                type="checkbox"
                id="isDateTime"
                name="isDateTime"
                className="ml-2"
                defaultChecked={data.isDateTime}
                onBlur={(e) => Chatbot.setBlockData(node.id, {[e.target.name]: e.target.checked})}
              />
            </div>
            {Object.keys(decisions)
              .sort()
              .map((key, i) => {
                const item = decisions[key];
                return (
                  <div key={item.id} className="input-group mb-3">
                    <span className="input-group-text">Text</span>
                    <input
                      name={`decisions.${item.id}.text`}
                      className="form-control"
                      placeholder="option"
                      defaultValue={item.text}
                      onBlur={(e) => {
                        Chatbot.setBlockData(node.id, {decisions: {[item.id]: {id: item.id, text: e.target.value}}});
                      }}
                    />
                    <span className="input-group-text">Value</span>
                    <input
                      id={`${item.id}.value`}
                      name={`decisions.${item.id}.value`}
                      className="form-control"
                      placeholder="value"
                      defaultValue={item.value}
                      onBlur={(e) =>
                        Chatbot.setBlockData(node.id, {decisions: {[item.id]: {id: item.id, value: e.target.value}}})
                      }
                    />
                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                    <span
                      className="input-group-text btn btn-outline-danger border-0"
                      onClick={(e) => removeDecisionField(e, item.id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </span>
                  </div>
                );
              })}
            <div id="decisions" className="input-group">
              <label htmlFor="decisions" className="d-none">
                Decisions
              </label>
              <span className="input-group-text">Text</span>
              <input
                type="text"
                id="txtText"
                className="form-control"
                placeholder="option"
                ref={refTxtText}
                onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
              />
              <span className="input-group-text">Value</span>
              <input
                type="text"
                id="txtValue"
                className="form-control"
                placeholder="value"
                ref={refTxtValue}
                onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
                onBlur={(e) => addDecisionField(e)}
              />
              <span className="input-group-text btn btn-outline-success border-0" onClick={(e) => addDecisionField(e)}>
                <i className="fa fa-plus"></i>
              </span>
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node decision-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.content}} />
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>

          <hr />
          {Object.keys(decisions)
            .sort()
            .map((key, i) => {
              const item = decisions[key];
              return (
                <div key={i} className="mb-3 position-relative">
                  {data.variable} : {item.value}
                  <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                </div>
              );
            })}
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="target" className="block-handle" type="target" position="top" />
    </>
  );
};
