import React, {useRef} from 'react';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';
import Select from 'react-select';
import {find as _find} from 'lodash';
import moment from 'moment';

export const MultipleChoiceBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const flowSelectRef = useRef(null);

  if (!block) {
    return null;
  }
  const {
    data,
    editMode,
    data: {options = {}},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor, optionsContent} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  const addFlowField = (e) => {
    e.preventDefault();
    let flowSelectOptions = flowSelectRef.current.state.value;
    if (flowSelectOptions) {
      const _option = data?.options[flowSelectOptions.id];
      Chatbot.setBlockData(node.id, {
        options: {...data?.options, [flowSelectOptions.id]: {..._option, hasUniqueFlow: true}},
      });
    }
  };

  const removeFlowField = (e, id) => {
    e.preventDefault();
    Chatbot.removeBlockFlow(node.id, id);
  };
  const addOptions = (e) => {
    const _options = e.target.value.split(/\n/);
    let optionsToAdd = {};
    for (let i = 0; i < _options.length; i++) {
      const id = moment().add(i, 'ms').valueOf().toString();
      const option = {id, label: '', value: '', hasUniqueFlow: false, next: null};
      const optionToAdd = _options[i];
      if (optionToAdd !== '') {
        let currentOption = null;
        if (optionToAdd.includes('|')) {
          const [label, value] = optionToAdd.split('|');
          option.label = label;
          option.value = value;
          currentOption = _find(options, {label});
        } else {
          option.label = optionToAdd;
          option.value = optionToAdd;
          currentOption = _find(options, {label: optionToAdd});
        }
        if (currentOption && currentOption.id.length > 3) {
          optionsToAdd[currentOption.id] = Object.assign(option, currentOption);
        } else {
          optionsToAdd[id] = option;
        }
      }
    }
    Chatbot.setBlockData(block.id, {
      assign: {...data, options: optionsToAdd, meta: {...data.meta, optionsContent: e.target.value}},
    });
  };

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-tasks"></i> Multiple Choice
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag multiple-choice-block">
            <div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label d-none">
                  Content
                </label>
                <div id="content">
                  <CKEditor
                    config={ckEditorBasic}
                    editor={ClassicEditor}
                    data={data.content}
                    onBlur={(e, editor) => {
                      Chatbot.setBlockData(block.id, {content: editor.getData()});
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="options" className="form-label">
                  Options
                </label>
                <textarea
                  id="options"
                  defaultValue={optionsContent}
                  onBlur={addOptions}
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="variable" className="form-label">
                  Variable
                </label>
                <input
                  type="input"
                  id="variable"
                  name="variable"
                  className="form-control"
                  placeholder="selected_choice"
                  defaultValue={data.variable}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {variable: e.target.value});
                  }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="variable" className="form-label">
                  Hint Message
                </label>
                <input
                  type="input"
                  id="hint"
                  name="hint"
                  className="form-control"
                  placeholder="Type input hint message here"
                  defaultValue={data.hint}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                  }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="webFunction" className="form-label">
                  Web function (optional)
                </label>
                <input
                  type="input"
                  id="webFunction"
                  name="webFunction"
                  className="form-control"
                  placeholder="setBankName"
                  defaultValue={data.webFunction}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value.trim()});
                  }}
                />
              </div>

              <label htmlFor="flows">Option that have different flow (optional)</label>
              <div className="input-group">
                <Select
                  ref={flowSelectRef}
                  id="flows"
                  name="flows"
                  placeholder="Select option"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  options={data?.options ? Object.values(data?.options) : []}
                />
                <button
                  className="input-group-text btn btn-outline-success border-0 my-1"
                  onClick={(e) => addFlowField(e)}
                >
                  Add
                </button>
              </div>
              {data?.options &&
                Object.values(data?.options)
                  ?.filter((i) => i.hasUniqueFlow)
                  .map((item) => {
                    return (
                      <div key={item.id} className="input-group my-2">
                        <span className="input-group-text">{item.label}</span>

                        <button
                          className="delete-btn input-group-text btn btn-outline-danger border-0"
                          onClick={(e) => removeFlowField(e, item.id)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>

                        <Handle
                          id={item.id}
                          className="block-handle block-handle-right"
                          type="source"
                          position="right"
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node multiple-choice-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.content}}></div>
          <b>
            <p className="mb-0">Options</p>
          </b>
          <div className="h-5rem overflow-auto mb-3 mt-0">
            {data?.options &&
              Object.values(data?.options).map((item, index) => {
                return (
                  <div key={index} className="my-3 position-relative">
                    <span>
                      {item.label} : {item.value}
                    </span>
                  </div>
                );
              })}
          </div>
          <b>
            <p>Flow</p>
          </b>
          {data?.options &&
            Object.values(data?.options)
              .filter((i) => i.hasUniqueFlow)
              .map((item, index) => {
                return (
                  <div key={index} className="my-3 position-relative">
                    <span>{item.value}</span>
                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                  </div>
                );
              })}
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
};
