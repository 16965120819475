import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {ColorPicker} from '../ColorPicker';

export const DocumentBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const [documents, setdocuments] = useState([]);
  const {start, review} = useSelector(Chatbot.get());
  useEffect(() => {
    const fetchDocuments = async () => {
      const {documents} = await Chatbot.fetchDocuments();
      setdocuments(documents?.map((i) => ({label: i.name, value: i.id})));
    };
    fetchDocuments();
  }, []);

  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-file-alt"></i> Document
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <Form url="/block/document" initialValues={data} onSuccess={onSuccess} successNotification={false}>
              <div className="mb-2">
                <label htmlFor="documentId" className="form-label">
                  Document
                </label>
                <Select
                  id="documentId"
                  name="documentId"
                  options={documents}
                  placeholder="Select Document"
                  value={documents?.find((i) => i.value === data.documentId)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {documentId: option.value, documentName: option.label});
                  }}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="fileName" className="form-label">
                  File name (attachment name, file name for drive)
                </label>
                <input
                  type="input"
                  id="fileName"
                  name="fileName"
                  className="form-control"
                  placeholder="Profile {first_name}"
                  defaultValue={data.fileName}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {fileName: e.target.value});
                  }}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="variableForUrl" className="form-label">
                  Variable (to store the document url "drive path")
                </label>
                <input
                  type="input"
                  id="variableForUrl"
                  name="variableForUrl"
                  className="form-control"
                  placeholder="document_url"
                  defaultValue={data.variableForUrl}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {variableForUrl: e.target.value});
                  }}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="variableForDownload" className="form-label">
                  Variable (to store the document download url)
                </label>
                <input
                  type="input"
                  id="variableForDownload"
                  name="variableForDownload"
                  className="form-control"
                  placeholder="document_download_url"
                  defaultValue={data.variableForDownload}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {variableForDownload: e.target.value});
                  }}
                />
              </div>
              <div className="form-footer">
                <Link to="/documents" target="_blank">
                  <button type="button" className="btn btn-dark btn-sm">
                    Create new
                  </button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? ''}}
          className="node document-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          <p>{data.documentName}</p>

          <a target="_blank" rel="noreferrer" href={`https://docs.google.com/document/d/${data.documentId}/edit`}>
            Click to open Google docs
          </a>
          {/* <a style={{color: textColor}} target="_blank" rel="noreferrer" href={`/document/${data.documentId}`}>
            {data.documentName}
          </a> */}
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
