import React from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';

export const RichTextBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="far fa-edit"></i> Rich Text
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />{' '}
              <i
                style={{color: textColor}}
                onClick={() => onClose(block.id)}
                className="ms-1 me-2 icon fa fa-times"
              ></i>
            </div>
          </div>
          <div className="block-body rich-text-block nodrag">
            <label htmlFor="content" className="form-label d-none">
              Content
            </label>
            <div id="content">
              <CKEditor
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                  });
                }}
                config={{
                  simpleUpload: {
                    uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                  },
                }}
                editor={ClassicEditor}
                data={data.content}
                onBlur={(e, editor) => {
                  Chatbot.setBlockData(block.id, {content: editor.getData()});
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.content}} />
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
