import React, {useEffect, useState} from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';

import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {axios} from '../../libs/axios';
import {ColorPicker} from '../ColorPicker';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';

// const lawyerList = [{label: 'Lawyer', value: 1}];

export const ScoroBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const [lawyerList, setLawyers] = useState([]);

  useEffect(() => {
    (async () => {
      const {lawyers} = await axios.get('booking/lawyers');
      setLawyers(
        lawyers?.map((i) => {
          return {label: i.full_name, value: i.id, email: i.email};
        }),
      );
    })();
  }, []);
  if (!block) {
    return null;
  }

  const variableOptions = Chatbot.getBlockVariables();
  const {
    data,
    editMode,
    data: {user = {}, lawyers = []},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="far fa-calendar-alt"></i> Scoro
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body booking-block nodrag">
            <div>
              <label htmlFor="user" className="form-label d-none">
                User Data
              </label>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <Select
                    id="firstName"
                    name="firstName"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.firstName)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {firstName: option.value}});
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <Select
                    id="lastName"
                    name="lastName"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.lastName)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {lastName: option.value}});
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Select
                    id="email"
                    name="email"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.email)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {email: option.value}});
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <Select
                    id="phone"
                    name="phone"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.phone)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {phone: option.value}});
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="caseTitle" className="form-label">
                Case Title
              </label>
              <input
                id="caseTitle"
                name="caseTitle"
                type="input"
                placeholder="Expiry of enforcement proceedings vs {_first_name_} {_last_name_};"
                className="form-control"
                defaultValue={data.caseTitle}
                onBlur={(e) => Chatbot.setBlockData(block.id, {caseTitle: e.target.value})}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="caseDescription" className="form-label">
                Case Description
              </label>
              <div id="caseDescription">
                <CKEditor
                  config={ckEditorBasic}
                  editor={ClassicEditor}
                  data={data.caseDescription}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {caseDescription: editor.getData()});
                  }}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="lawyers" className="form-label">
                Lawyers
              </label>
              <Select
                id="lawyers"
                name="lawyers"
                placeholder="Select Lawyer(s)"
                className="form-control form-control-sm react-select-container"
                classNamePrefix="react-select"
                isMulti
                options={lawyerList}
                value={lawyerList?.filter((item) => lawyers.includes(item.value))}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {
                    assign: {...data, lawyers: option.map((i) => i.value), lawyerEmails: option.map((i) => i.email)},
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node booking-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <p>Case Title: {data.caseTitle}</p>
          <p>Case Description: </p>
          <span dangerouslySetInnerHTML={{__html: data.caseDescription}}></span>
          <p>
            Lawyers:{' '}
            {lawyerList
              ?.filter((item) => lawyers.includes(item.value))
              .map((i, index) => (
                <span key={index}>{i.label}</span>
              ))}
          </p>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
