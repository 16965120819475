import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {axios} from '../libs';
import {User} from '../states/actions';

export const Sidebar = () => {
  const user = useSelector(User.get());
  const {replace} = useHistory();
  useEffect(() => {
    window.initSidebar();
  }, []);

  const logout = async () => {
    await axios.get('/logout');
    replace('/login');
  };

  return (
    <div className="col-md-3 left_col menu_fixed">
      <div className="left_col scroll-view">
        <div className="navbar nav_title">
          <Link to="/" className="site_title">
            {/* <i className="fa fa-h-square"></i> */}
            <img src="/images/hugo_logo_black.png" alt="..." />
            <span> HUGO - Legal</span>
          </Link>
        </div>
        <div className="clearfix"></div>
        <div className="profile clearfix">
          <div className="profile_pic">
            <img src={user.avatar} alt="..." className="img-circle profile_img" />
          </div>
          <div className="profile_info">
            <span>Welcome,</span>
            <h2>{user.fullName}</h2>
          </div>
          <div className="clearfix"></div>
        </div>
        <br />
        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
          <div className="menu_section">
            <ul className="nav side-menu">
              {User.isAdmin() && (
                <li>
                  <Link to="/users">
                    <i className="fas fa-users"></i> Users
                  </Link>
                </li>
              )}
              <li>
                <Link to="/chatbots">
                  <i className="fas fa-robot"></i> Chatbots
                </Link>
              </li>
              <li>
                <Link to="/documents">
                  <i className="fas fa-file-invoice"></i> Documents
                </Link>
              </li>

              {User.isAdmin() && (
                <li className="sub_menu">
                  <span>
                    <i className="fas fa-cogs"></i> Settings <span className="fas fa-chevron-down"></span>
                  </span>
                  <ul className="nav child_menu">
                    <li>
                      <Link to="/email-contents">Email Contents</Link>
                    </li>
                    <li>
                      <Link to="/email-templates">Email Templates</Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="sidebar-footer">
          <span className="hidden-small" title="Settings">
            <i className="fas fa-user-circle"></i>
          </span>
          <span className="hidden-small" title="FullScreen">
            <i className="fa fa-expand"></i>
          </span>
          <span id="menu_toggle" title="Hide">
            <i className="fa fa-eye-slash"></i>
          </span>
          <span onClick={logout} className="hidden-small" title="Logout">
            <i className="fa fa-power-off"></i>
          </span>
        </div>
      </div>
    </div>
  );
};
