import React, {useState} from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {find as _find} from 'lodash';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';

import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {Chatbot} from '../../states/actions';
import {ckEditorEmailBlock} from '../../config';
import {ColorPicker} from '../ColorPicker';

export const EmailBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review, blocks} = useSelector(Chatbot.get());
  const documentBlockExist = _find(blocks, {type: 'documentBlock'});
  const [documents, setdocuments] = useState([]);

  if (!block) {
    return null;
  }

  const variableOptions = Chatbot.getBlockVariables();
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  const loadDocuments = async (inputValue) => {
    const {documents} = await Chatbot.fetchDocuments();
    setdocuments(documents);
    return documents?.map((i) => ({label: i.name, value: i.id}));
  };

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-at"></i> Email
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-3 row">
              <label htmlFor="email" className="col-sm-2 col-form-label">
                Email
              </label>
              <div className="col-sm-10">
                <Select
                  className="form-control form-control-sm"
                  placeholder="Select email variable"
                  id="email"
                  name="email"
                  options={variableOptions}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {email: option.value});
                  }}
                  value={variableOptions.find((item) => item.value === data.email)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      border: 'none',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="cc" className="col-sm-2 col-form-label">
                CC
              </label>
              <div className="col-sm-10">
                <CreatableSelect
                  isClearable
                  className="form-control form-control-sm"
                  id="cc"
                  isMulti
                  options={data?.cc?.map((i) => ({label: i, value: i}))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {cc: option.map((i) => i.value)});
                  }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="bcc" className="col-sm-2 col-form-label">
                BCC
              </label>
              <div className="col-sm-10">
                <CreatableSelect
                  className="form-control form-control-sm"
                  id="bcc"
                  isMulti
                  options={data?.bcc?.map((i) => ({label: i, value: i}))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {bcc: option.map((i) => i.value)});
                  }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="subject" className="col-sm-2 col-form-label">
                Subject
              </label>
              <div className="col-sm-10">
                <input
                  id="subject"
                  name="subject"
                  type="input"
                  placeholder="Write Subject"
                  className="form-control"
                  defaultValue={data.subject}
                  onBlur={(e) => Chatbot.setBlockData(block.id, {subject: e.target.value})}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="content" className="form-label d-none">
                Content
              </label>
              <div id="content">
                <CKEditor
                  config={ckEditorEmailBlock}
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
            </div>
            {documentBlockExist && (
              <div className="mb-3">
                <label htmlFor="attachment" className="form-label ">
                  Attachment
                </label>
                <AsyncSelect
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  id="attachment"
                  placeholder="Select Attachment"
                  name="attachment"
                  loadOptions={loadDocuments}
                  value={documents
                    ?.filter((doc) => data?.attachment?.includes(doc.id))
                    .map((i) => ({label: i.name, value: i.id}))}
                  defaultOptions
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {
                      assign: {...data, attachment: option.map((i) => i.value)},
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node email-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <p>Email Variable: {data.email}</p>
          <div className="my-3">
            {data.cc?.map((i) => (
              <div key={i}>
                <span>CC: </span>
                <span>{i} </span>
              </div>
            ))}
          </div>
          <div className="my-3">
            {data.bcc?.map((i) => (
              <div key={i}>
                <span>BCC: </span>
                <span>{i} </span>
              </div>
            ))}
          </div>
          <div
            className="my-3"
            dangerouslySetInnerHTML={{
              __html: data.content.replace('width:40%;', '').replace('padding:15px;', 'padding:5px;'),
            }}
          />
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
