import React from 'react';
import {User} from '../../states/actions';

export const Sidebar = () => {
  const onDragStart = (e) => {
    let {type, blockType} = e.target.dataset;
    e.dataTransfer.setData('blockType', JSON.stringify({type, blockType}));
  };

  return (
    <>
      <div className="blocks-menu h-100">
        <div className="block-item" data-type="text" data-block-type="textBlock" onDragStart={onDragStart} draggable>
          <i className="fa fa-font"></i>
          <span className="">Text</span>
        </div>
        <div className="block-item" data-type="input" data-block-type="inputBlock" onDragStart={onDragStart} draggable>
          <i className="fa fa-question"></i>
          <span className="">Input</span>
        </div>
        <div
          className="block-item"
          data-type="decision"
          data-block-type="decisionBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="far fa-list-alt"></i>
          <span className="">Decision</span>
        </div>
        <div
          className="block-item"
          data-type="condition"
          data-block-type="conditionBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fa fa-exchange-alt"></i>
          <span className="">Condition</span>
        </div>
        {/* <div
          className="block-item"
          data-type="teleport"
          data-block-type="teleportBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fas fa-rocket"></i>
          <span className="">Teleport</span>
        </div> */}
        <div className="block-item" data-type="sendy" data-block-type="sendyBlock" onDragStart={onDragStart} draggable>
          <i className="fas fa-envelope-open-text"></i>
          <span className="">Sendy</span>
        </div>
        {/* <div
          className="block-item"
          data-type="redirect"
          data-block-type="redirectBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fas fa-directions"></i>
          <span className="">Redirect</span>
        </div> */}
        <div
          className="block-item"
          data-type="document"
          data-block-type="documentBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fa fa-file-alt"></i>
          <span className="">Document</span>
        </div>
        <div className="block-item" data-type="gtm" data-block-type="gtmBlock" onDragStart={onDragStart} draggable>
          <i className="fab fa-google"></i>
          <span className="">Google Tag Manager</span>
        </div>
        <div className="block-item" data-type="data" data-block-type="dataBlock" onDragStart={onDragStart} draggable>
          <i className="fas fa-database"></i>
          <span className="">Data</span>
        </div>
        <div
          className="block-item"
          data-type="richText"
          data-block-type="richTextBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="far fa-edit"></i>
          <span className="">Rich Text</span>
        </div>
        {User.isAdmin() && (
          <div
            className="block-item"
            data-type="booking"
            data-block-type="bookingBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-book"></i>
            <span className="">Booking</span>
          </div>
        )}
        <div className="block-item" data-type="email" data-block-type="emailBlock" onDragStart={onDragStart} draggable>
          <i className="fas fa-at"></i>
          <span className="">Email</span>
        </div>
        {User.isAdmin() && (
          <div
            className="block-item"
            data-type="pipedrive"
            data-block-type="pipedriveBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-handshake"></i>
            <span className="">Pipedrive</span>
          </div>
        )}
        <div className="block-item" data-type="scoro" data-block-type="scoroBlock" onDragStart={onDragStart} draggable>
          <i className="far fa-calendar-alt"></i>
          <span className="">Scoro</span>
        </div>
        <div
          className="block-item"
          data-type="upload"
          data-block-type="uploadBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fas fa-upload"></i>
          <span className="">Upload</span>
        </div>
        <div
          className="block-item"
          data-type="multipleChoice"
          data-block-type="multipleChoiceBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fas fa-tasks"></i>
          <span className="">Multiple Choice</span>
        </div>
        {User.isAdmin() && (
          <div
            className="block-item"
            data-type="dieselgate"
            data-block-type="dieselgateBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-square-root-alt"></i>
            <span className="">Dieselgate</span>
          </div>
        )}
      </div>
    </>
  );
};
