import React, {useEffect, useRef, useState} from 'react';
import ReactFlow, {Controls, Background} from 'react-flow-renderer';
import {Sidebar} from '../components/ChatbotBuilder';
import {axios} from '../libs';
import {Chatbot} from '../states/actions';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {nodeTypes} from '../components/ChatbotBuilder/nodeTypes';
import {Form} from '../components';
import {Field} from 'formik';
import {find as _find, filter as _filter} from 'lodash';
import Chat from '../components/ChatbotBuilder/ChatWidget/Chat';
import {User} from '../states/actions';

export const ChatbotBuilder = React.memo(() => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const chatbot = useSelector(Chatbot.get());
  const {name, blocks} = chatbot;
  const {chatbotId} = useParams();
  const oldWarn = console.warn;
  console.warn = (warning) => {
    if (warning.includes(`couldn't create edge for`)) {
      const handleId = warning.split(':').pop();
      console.log(`Removing un-created handles`, handleId.trim());
      Chatbot.removeBlock(handleId.trim(), true);
    } else {
      oldWarn(warning);
    }
  };
  useEffect(() => {
    window.collapseSidebar();
    Chatbot.fetch(chatbotId).then((chatbot) => {
      // const _blocks = Object.values(chatbot.blocks);
      // const _handles = _filter(_blocks, {data: {type: 'handle'}});
      // _handles.forEach(({source, target, id, sourceHandle}) => {
      //   // remove un handeled edges
      //   const sourceBlock = _find(_blocks, {id: source});
      //   let targetBlock = _find(_blocks, {id: target});
      //   if (sourceBlock && targetBlock) {
      //     //check block type first
      //     if (!['decisionBlock', 'multipleChoiceBlock'].includes(sourceBlock.type)) {
      //       // source and target blocks are present
      //       if (sourceBlock.data.next !== targetBlock.id) {
      //         console.log('REmove this handle', id);
      //         delete chatbot.blocks[id];
      //         Chatbot.removeBlock(id, true);
      //       }
      //     } else {
      //       if (sourceBlock.type === 'multipleChoiceBlock') {
      //         if (sourceHandle.includes) {
      //           if (sourceHandle.includes('handle-')) {
      //             console.log('REmove this handle', id);
      //             delete chatbot.blocks[id];
      //             Chatbot.removeBlock(id, true);
      //           }
      //         }
      //       }
      //     }
      //   } else if (sourceBlock) {
      //     // only source block exists
      //     console.log('REmove this handle', id);
      //     delete chatbot.blocks[id];
      //     Chatbot.removeBlock(id, true);
      //   } else if (targetBlock) {
      //     console.log('REmove this handle', id);
      //     // only target block exists
      //     delete chatbot.blocks[id];
      //     Chatbot.removeBlock(id, true);
      //   }
      // });

      Chatbot.set(chatbot);
    });
    //get chatbot blocks
  }, [chatbotId]);

  const onDrop = async (e) => {
    try {
      e.preventDefault();
      const {type, blockType} = JSON.parse(e.dataTransfer.getData('blockType'));
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top,
      });
      const _id = await getBlockId();
      Chatbot.setBlock({
        id: _id,
        type: blockType,
        position,
        editMode: true,
        data: {
          _id,
          type,
          chatbotId,
          meta: {
            position,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onNodeDragStop = (event, node) => {
    // //check if edit mode on
    const editMode = blocks[node.id].editMode;
    Chatbot.setBlockData(
      node.id,
      {
        meta: {
          position: node.position,
        },
      },
      !editMode,
    );
  };

  const onConnect = async (params) => {
    console.log(`file: ChatbotBuilder.js | line 97 | params`, params);
    const blockType = blocks[params.source].data.type;
    const _id = await getBlockId();

    let label = null;
    if (blockType === 'decision') {
      label = `${blocks[params.source].data['variable']} : ${
        blocks[params.source].data.decisions[params.sourceHandle].text
      }`;
    } else {
      label = blocks[params.source].data['variable'] || '';
    }

    const handle = {
      id: _id,
      source: params.source,
      sourceHandle: params.sourceHandle,
      target: params.target,
      targetHandle: params.targetHandle,
      type: 'step',
      // label,
      data: {},
    };

    Chatbot.setBlock(handle);
    Chatbot.setBlockData(
      _id,
      {
        _id,
        type: 'handle',
        chatbotId,
        meta: {handle},
      },
      true,
    );

    if (blockType === 'condition') {
      Chatbot.setBlockData(
        params.source,
        {
          [params.sourceHandle === 'true' ? 'nextOnTrue' : 'nextOnFalse']: params.target,
        },
        true,
      );
    } else if (blockType === 'decision') {
      Chatbot.setBlockData(
        params.source,
        {
          decisions: {
            [params.sourceHandle]: {next: params.target},
          },
        },
        true,
      );
    } else if (blockType === 'multipleChoice') {
      if (params.sourceHandle === 'next') {
        Chatbot.setBlockData(
          params.source,
          {
            next: params.target,
          },
          true,
        );
      } else {
        const _options = blocks[params.source].data.options;
        const _option = _find(_options, {id: params.sourceHandle});
        Chatbot.setBlockData(
          params.source,
          {
            options: {..._options, [_option.id]: {..._option, next: params.target}},
          },
          true,
        );
      }
    } else {
      Chatbot.setBlockData(
        params.source,
        {
          next: params.target,
        },
        true,
      );
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onBlockRemove = (blocksToDelete) => {
    blocksToDelete.forEach((block) => {
      Chatbot.removeBlock(block.id, true);
    });
  };

  const onPanelClick = async () => {
    const block = _find(chatbot.blocks, {editMode: true});
    if (block) {
      //set editmode to false and post data to api
      const response = await Chatbot.setBlockData(block.id, {}, true);
      if (!response.error) {
        Chatbot.setBlock({id: block.id, editMode: false});
      } else {
        let {error, message} = response;
        window.nError({title: 'Opps!', text: message, icon: 'fa-exclamation-triangle', error});
      }
    }
  };

  const getBlockId = async () => {
    let {id} = await axios.get('/block/id');
    //error checking
    return id;
  };

  return (
    <>
      <div className="row">
        <div className="col p-0">
          <div className="x_panel chatbot-builder">
            <div className="x_title d-flex justify-content-between">
              <h2>
                Chat Builder: <b>{name}</b>
              </h2>
              <div>
                {User.isAdmin() ? (
                  <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalPublish">
                    Publish
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#modalSubmit">
                    Submit for approval
                  </button>
                )}
                <button
                  className="btn btn-info btn-sm"
                  style={{marginLeft: '15px'}}
                  onClick={() => {
                    window.open(`/chatbot-variables/${chatbotId}`, '', `width=600,height=${window.screen.height}`);
                  }}
                >
                  Show Variables
                </button>
              </div>
            </div>
            <div className="x_content">
              {/* <ChatPreview /> */}
              <Chat />
              <div className="row h-100">
                <div className="col-2 p-0">
                  <Sidebar />
                </div>
                <div className="col-10 p-0" ref={reactFlowWrapper}>
                  <ReactFlow
                    onLoad={(instance) => {
                      setReactFlowInstance(instance);
                    }}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    elements={Object.values(blocks)}
                    snapToGrid={true}
                    nodeTypes={nodeTypes}
                    onElementsRemove={onBlockRemove}
                    deleteKeyCode={46}
                    onConnect={onConnect}
                    onNodeDragStop={onNodeDragStop}
                    onPaneClick={onPanelClick}
                    minZoom={0.1}
                  >
                    <Controls />
                    <Background gap={12} />
                  </ReactFlow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalSubmit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <Form url="/chatbot/submit" initialValues={{chatbotId}}>
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  Submit
                </h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label d-none">
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    className="form-control"
                    placeholder="This bot deals with all the required employment contracts"
                    rows="6"
                  />
                  <div className="form-text">
                    Write a small description about your chatbot, (also if you apply for changes then mention what has
                    changed)
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" className="btn btn-primary btn-sm">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalPublish"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="staticBackdropLabel">
                Publish this Chatbot
              </h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="description" className="form-label d-none">
                  Chatbot Script Tag
                </label>
                <textarea
                  id="publishCode"
                  name="publishCode"
                  className="form-control"
                  placeholder=""
                  rows="12"
                  value={`<link href="https://dev-admin.hugo.legal/widget/css/chat-widget.css" rel="stylesheet" />


<script>
  var chatbotId = '${chatbotId}';
  var lang = 'en';
</script>
<script src="https://dev-admin.hugo.legal/widget/js/runtime.js"></script>
<script src="https://dev-admin.hugo.legal/widget/js/chunk.js"></script>
<script src="https://dev-admin.hugo.legal/widget/js/main.js"></script>`}
                />
                <div className="form-text">Add this code to your website's "head" tag</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
