import React, {useEffect} from 'react';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import Select from 'react-select';

export const DieselgateBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  useEffect(() => {
    if (block.id) {
      Chatbot.setBlockData(block.id, {mileageForCalculation: 250000});
    }
  }, []);

  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');
  const variableOptions = Chatbot.getBlockVariables();

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-square-root-alt"></i> Dieselgate
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-2">
              <label htmlFor="mileageForCalculation" className="form-label">
                Mileage for calculation
              </label>
              <input
                id="mileageForCalculation"
                name="mileageForCalculation"
                type="input"
                placeholder="Insert legal cost"
                className="form-control"
                defaultValue={data.mileageForCalculation}
                onBlur={(e) => Chatbot.setBlockData(block.id, {mileageForCalculation: e.target.value})}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="carUsed" className="form-label">
                Car Used (should contain true or false value)
              </label>
              <Select
                className="form-control form-control-sm"
                placeholder="Select Car used variable"
                id="carUsed"
                name="carUsed"
                options={variableOptions}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {carUsed: option.value});
                }}
                value={variableOptions.find((item) => item.value === data.carUsed)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="usedCarMileage" className="form-label">
                Used Car Mileage (In case of used car)
              </label>
              <Select
                className="form-control form-control-sm"
                placeholder="Select Used car mileage variable"
                id="usedCarMileage"
                name="usedCarMileage"
                options={variableOptions}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {usedCarMileage: option.value});
                }}
                value={variableOptions.find((item) => item.value === data.usedCarMileage)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="carMileage" className="form-label">
                Car Mileage
              </label>
              <Select
                className="form-control form-control-sm"
                placeholder="Select Car mileage variable"
                id="carMileage"
                name="carMileage"
                options={variableOptions}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {carMileage: option.value});
                }}
                value={variableOptions.find((item) => item.value === data.carMileage)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <Select
                className="form-control form-control-sm"
                placeholder="Select Price variable"
                id="price"
                name="price"
                options={variableOptions}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {price: option.value});
                }}
                value={variableOptions.find((item) => item.value === data.price)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
            </div>
            <hr />
            <div className="mb-2">
              <label htmlFor="legalCost" className="form-label">
                Legal Cost (Output)
              </label>
              <input
                id="legalCost"
                name="legalCost"
                type="input"
                placeholder="legal_cost"
                className="form-control"
                defaultValue={data.legalCost}
                onBlur={(e) => Chatbot.setBlockData(block.id, {legalCost: e.target.value})}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="claim" className="form-label">
                Claim (Output)
              </label>
              <input
                id="claim"
                name="claim"
                type="input"
                placeholder="claim"
                className="form-control"
                defaultValue={data.claim}
                onBlur={(e) => Chatbot.setBlockData(block.id, {claim: e.target.value})}
              />
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node text-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}

          <p className="mt-2">Mileage for calculation: {data.mileageForCalculation}</p>
          <p>Price: {data.price}</p>
          <p>Car Used: {data.carUsed}</p>
          <p>Used Car Mileage: {data?.usedCarMileage}</p>
          <p>Car Mileage: {data.carMileage}</p>
          <p>Legal Cost: {data.legalCost}</p>
          <p>Claim: {data.claim}</p>
          <p>Car Used: {data.carUsed}</p>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
