import React from 'react';
import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Field} from 'formik';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose} from '../../helpers/block-helper';

export const RedirectBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  return (
    <>
      {editMode && (
        <div className="block active" onDoubleClick={() => onEdit(node)}>
          <div className="block-header">
            <div>
              <i className="fas fa-directions"></i> Redirect
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 icon fa fa-cog"></i>
              <i className="mx-1 icon fa fa-play-circle"></i>
              <i onClick={() => onClose(block.id)} className="ms-1 me-2 icon fa fa-times"></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <Form url="/block/redirect" initialValues={data} onSuccess={onSuccess} successNotification={false}>
              <div>
                <div className="mb-3">
                  <label htmlFor="content" className="form-label">
                    Content
                  </label>
                  <Field
                    id="content"
                    name="content"
                    className="form-control nowheel"
                    placeholder="Click here to visit our booking page"
                  >
                    {data.content}
                  </Field>
                </div>
                <div className="mb-3">
                  <label htmlFor="url" className="form-label">
                    Link
                  </label>
                  <Field id="url" name="url" className="form-control nowheel" placeholder="https://hugo.legal">
                    {data.url}
                  </Field>
                </div>
                <div className="form-footer">
                  <button type="button" className="btn btn-sm btn-secondary" onClick={() => onRemove(block.id)}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                  <button type="submit" className="btn btn-sm btn-success">
                    <i className="fa fa-save"></i>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
      {!editMode && (
        <div className="node text-block" onDoubleClick={review ? null : () => onEdit(node)}>
          <a href={data.url}>{data.content}</a>
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
};
