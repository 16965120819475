import React, {useEffect} from 'react';
import {Field} from 'formik';
import {Form} from '../components';
import {axios} from '../libs';

export const Login = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    request: '',
  };

  const onSuccess = (res) => {};

  useEffect(() => {
    document.body.classList.add('login');
    document.body.classList.remove('nav-md', 'nav-sm');
  }, []);

  const onGoogleLogin = async () => {
    const {authUrl} = await axios.get('/google-login');
    window.location = authUrl;
  };

  return (
    <>
      <div className="login">
        <div className="login_wrapper">
          <div className="login_form">
            <section className="login_content">
              <Form url="/user-request" initialValues={initialValues} onSuccess={onSuccess}>
                <h1>Request Access</h1>
                <div>
                  <label htmlFor="firstName" className="left">
                    First Name
                  </label>
                  <Field
                    id="firstName"
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="John"
                    autoComplete="name"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="left">
                    Last Name
                  </label>
                  <Field
                    id="lastName"
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Doe"
                    autoComplete="name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="left">
                    Email
                  </label>
                  <Field
                    id="email"
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="user@domain.com"
                    autoComplete="username"
                  />
                </div>
                <div>
                  <label htmlFor="request" className="left">
                    Request Message
                  </label>
                  <Field
                    id="request"
                    as="textarea"
                    name="request"
                    className="form-control"
                    placeholder="Hi, my name is John, a lawstudent from Tallin"
                  />
                </div>
                <div className="d-grid my-3">
                  <button className="btn btn-primary">Signup</button>
                </div>
                <hr />
                <div className="d-grid">
                  <button onClick={onGoogleLogin} type="button" className="btn btn-danger">
                    <i className="fab fa-google"></i> Sign In with google
                  </button>
                </div>
                <div className="clearfix"></div>
                <div className="separator">
                  <div>
                    <img src="/images/hugo.legal.png" alt="Hugo legal logo" className="mb-4 mt-2" />
                    <p>©2021 All Rights Reserved.</p>
                  </div>
                </div>
              </Form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
