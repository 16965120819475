import React from 'react';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {acceptedFileTypes} from '../../config';
import {ColorPicker} from '../ColorPicker';
import Select from 'react-select';

export const UploadBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());

  if (!block) {
    return null;
  }
  const {data, editMode} = block;

  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-upload"></i> Upload
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="variable" className="form-label">
                  Variable
                </label>
                <input
                  id="variable"
                  name="variable"
                  type="input"
                  placeholder="file_url"
                  className="form-control"
                  defaultValue={data.variable}
                  onBlur={(e) => Chatbot.setBlockData(block.id, {variable: e.target.value})}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="filePrefix" className="form-label">
                  File Prefix
                </label>
                <input
                  id="filePrefix"
                  name="filePrefix"
                  type="input"
                  placeholder="hugo"
                  className="form-control"
                  defaultValue={data.filePrefix}
                  onBlur={(e) => Chatbot.setBlockData(block.id, {filePrefix: e.target.value})}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="sizeLimit" className="form-label">
                  File Size Limit (mb)
                </label>
                <input
                  id="sizeLimit"
                  name="sizeLimit"
                  type="input"
                  placeholder="10"
                  className="form-control"
                  defaultValue={data.sizeLimit}
                  onBlur={(e) => Chatbot.setBlockData(block.id, {sizeLimit: e.target.value})}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="fileSizeLimit" className="form-label">
                  Accepted File Type
                </label>
                <Select
                  id="acceptedTypes"
                  name="acceptedTypes"
                  placeholder="Select File Type"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  isMulti
                  options={acceptedFileTypes}
                  value={acceptedFileTypes.filter((item) => data?.acceptedTypes?.includes(item.value))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {assign: {...data, acceptedTypes: option.map((i) => i.value)}});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node text-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>

          <div className="my-3">File Prefix: {data.filePrefix}</div>
          <div className="my-3">Size Limit: {data.sizeLimit} mb</div>
          <div className="my-3">
            Accepted Files:{'  '}
            {data.acceptedTypes?.map((i, index) => (
              <span key={index}>{i} | </span>
            ))}
          </div>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
};
