import React, {useState} from 'react';
import {Field} from 'formik';
import {DataTable, Form} from '../components';
import {acceptFiles} from '../config';

export const Documents = React.memo(() => {
  const [refetch, setrefetch] = useState(false);
  const [isMultipart, setIsMultipart] = useState(false);

  const initialValues = {
    name: '',
    document: null,
  };

  const onSuccess = ({chatbot}) => {
    setrefetch(true);
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>New Document</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form
                enctype="multipart/form-data"
                multipart={isMultipart}
                url={isMultipart ? '/user/documents/upload' : '/user/documents'}
                initialValues={initialValues}
                onSuccess={onSuccess}
              >
                <div className="row mb-3">
                  <label htmlFor="name" className="col-3 col-form-label">
                    Document Name
                  </label>
                  <div className="col-9">
                    <Field id="name" name="name" type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="document" className="col-3 col-form-label">
                    Select Document
                  </label>
                  <div className="col-9">
                    <Field name="document">
                      {({field, form}) => (
                        <input
                          onChange={(event) => {
                            const {
                              target: {
                                files: [file],
                              },
                            } = event;
                            form.setFieldValue('document', file);
                            setIsMultipart(true);
                          }}
                          accept={acceptFiles.toString()}
                          type="file"
                          name="document"
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9  offset-md-3">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Documents</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content"></div>
            <DataTable
              onDelete={() => {}}
              refetch={refetch}
              navigateTo="https://docs.google.com/document/d"
              url="/user/documents"
              dataKey="documents"
              tableHeading="Documents"
              columns={tableColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
});
