import Select from 'react-select';
import React from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

import {Chatbot} from '../../states/actions';
import {validations, moneyCurrency, ckEditorInputBlock} from '../../config';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';

export const InputBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-question"></i> Input
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                onClick={() => onClose(block.id)}
                className="ms-1 me-2 icon fa fa-times"
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-3">
              <label htmlFor="content" className="form-label d-none">
                Content
              </label>
              <div id="content">
                <CKEditor
                  config={{
                    ...ckEditorInputBlock,
                    simpleUpload: {
                      uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                    },
                  }}
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="variable" className="form-label">
                Variable (to store the answer)
              </label>
              <input
                type="input"
                id="variable"
                name="variable"
                className="form-control"
                placeholder="name"
                defaultValue={data.variable}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="validation" className="form-label">
                Validation Rule
              </label>
              <Select
                id="validation"
                name="validation"
                options={validations}
                placeholder="Type"
                value={validations.find((i) => i.value === data.validation)}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {validation: option.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="hint" className="form-label">
                Hint Message
              </label>
              <input
                type="input"
                id="hint"
                name="hint"
                className="form-control"
                placeholder="Type input hint message here"
                defaultValue={data.hint}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            {data.validation === 'money' && (
              <div>
                <label htmlFor="Choose Currency" className="form-label">
                  Choose Currency
                </label>
                <Select
                  id="currency"
                  name="currency"
                  options={moneyCurrency}
                  placeholder="Type"
                  value={moneyCurrency.find((i) => i.value === data.currency)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {currency: option.value});
                  }}
                />
              </div>
            )}
            <div>
              <label htmlFor="validationMessage" className="form-label">
                Validation Message
              </label>
              <input
                type="input"
                id="validationMessage"
                name="validationMessage"
                className="form-control"
                placeholder="Validation Message"
                defaultValue={data.validationMessage}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {validationMessage: e.target.value});
                }}
              />
            </div>
          </div>
        </div>
      )}

      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node input-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.content}} />
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
