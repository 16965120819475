import React from 'react';
import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import Select from 'react-select';

export const SendyBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');
  const variableOptions = Chatbot.getBlockVariables();

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-envelope-open-text"></i> Sendy
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Select
                className="form-control form-control-sm"
                placeholder="Select email variable"
                id="email"
                name="email"
                options={variableOptions}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {email: option.value});
                }}
                value={variableOptions.find((item) => item.value === data.email)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="listId" className="form-label">
                List ID
              </label>
              <input
                type="input"
                id="listId"
                name="listId"
                className="form-control"
                placeholder="List ID"
                defaultValue={data.listId}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {listId: e.target.value});
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node text-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <span className="badge rounded-pill bg-primary badge-bl">{data.email}</span>
          <p>List ID</p>
          <span>{data.listId}</span>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
