import React, {useEffect, useState} from 'react';
import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';

import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {bookingTypes, durations} from '../../config';
import {axios} from '../../libs/axios';
import {ColorPicker} from '../ColorPicker';

// const lawyerList = [{label: 'Lawyer', value: 1}];

export const BookingBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const [lawyerList, setLawyers] = useState([]);

  useEffect(() => {
    Chatbot.setBlockData(block.id, {types: {phone: {}}});
    (async () => {
      const {lawyers} = await axios.get('booking/lawyers');
      setLawyers(
        lawyers?.map((i) => {
          return {label: i.full_name, value: i.id};
        }),
      );
    })();
  }, []);
  if (!block) {
    return null;
  }

  const variableOptions = Chatbot.getBlockVariables();
  const {
    data,
    editMode,
    data: {types = {phone: {}}, user = {}, lawyers = []},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-book"></i> Booking
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body booking-block nodrag">
            <div className="row mb-3">
              <label htmlFor="types" className="form-label">
                Booking Type
              </label>
              <br />
              <div className="btn-group btn-group-sm" role="group">
                {bookingTypes.map(({label, value}, i) => {
                  return (
                    <div key={i}>
                      <input
                        id={value}
                        disabled={value === 'meeting' || value === 'web' || value === 'video'}
                        name="types"
                        type="checkbox"
                        className="btn-check"
                        autoComplete="off"
                        defaultChecked={Object.keys(types).includes(value) ? 'checked' : false}
                        // onChange={(e) => {
                        //   if (e.target.checked) {
                        //     Chatbot.setBlockData(block.id, {types: {[value]: {}}});
                        //   }
                        //   else {
                        //     const {[value]: valueRemoved, ...rest} = data.types;
                        //     Chatbot.setBlockData(block.id, {assign: {...data, types: rest}});
                        //   }
                        // }}
                      />
                      <label className="form-control btn btn-outline-secondary" htmlFor={value}>
                        {label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <label htmlFor="user" className="form-label d-none">
                User Data
              </label>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <Select
                    id="firstName"
                    name="firstName"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.firstName)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {firstName: option.value}});
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <Select
                    id="lastName"
                    name="lastName"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.lastName)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {lastName: option.value}});
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Select
                    id="email"
                    name="email"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.email)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {email: option.value}});
                    }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <Select
                    id="phone"
                    name="phone"
                    placeholder="Variable"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={variableOptions}
                    value={variableOptions.find((item) => item.value === user.phone)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {user: {phone: option.value}});
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="caseTitle" className="form-label">
                Case Title
              </label>
              <input
                id="caseTitle"
                name="caseTitle"
                type="input"
                placeholder="Expiry of enforcement proceedings vs {_first_name_} {_last_name_};"
                className="form-control"
                defaultValue={data.caseTitle}
                onBlur={(e) => Chatbot.setBlockData(block.id, {caseTitle: e.target.value})}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lawyers" className="form-label">
                Lawyers
              </label>
              <Select
                id="lawyers"
                name="lawyers"
                placeholder="Select Lawyer(s)"
                className="form-control form-control-sm react-select-container"
                classNamePrefix="react-select"
                isMulti
                options={lawyerList}
                value={lawyerList?.filter((item) => lawyers.includes(item.value))}
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {assign: {...data, lawyers: option.map((i) => i.value)}});
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="textNoSlotsFound" className="form-label">
                Text (No time slots found)
              </label>
              <input
                id="textNoSlotsFound"
                name="textNoSlotsFound"
                type="input"
                placeholder="No timeslots found"
                className="form-control"
                defaultValue={data.textNoSlotsFound}
                onBlur={(e) => Chatbot.setBlockData(block.id, {textNoSlotsFound: e.target.value})}
              />
            </div>

            {types.phone && (
              <div id="phoneBooking">
                <div style={{color: txtColor ?? '#000000'}} className="block-header mb-3">
                  Phone Booking
                </div>
                <div className="mb-3">
                  <label htmlFor="textSelectTimeSlot" className="form-label">
                    Text (select suitable time slot)
                  </label>
                  <input
                    id="textSelectTimeSlot"
                    name="textSelectTimeSlot"
                    type="input"
                    placeholder="Please select a suitable time for your call"
                    className="form-control"
                    defaultValue={types.phone?.textSelectTimeSlot}
                    onBlur={(e) =>
                      Chatbot.setBlockData(block.id, {types: {phone: {textSelectTimeSlot: e.target.value}}})
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="duration" className="form-label">
                    Select meeting duration
                  </label>
                  <Select
                    id="duration"
                    name="duration"
                    placeholder="Select Duration"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={durations}
                    value={durations.find((item) => item.value === types.phone.duration)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {types: {phone: {duration: option.value}}});
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node booking-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <p>
            Booking Type:
            {Object.keys(data.types).map((i, index) => (
              <span key={index}> {i}</span>
            ))}
          </p>
          <p>Case Title: {data.caseTitle}</p>
          <p>
            Lawyers:{' '}
            {lawyerList
              ?.filter((item) => lawyers.includes(item.value))
              .map((i, index) => (
                <span key={index}>{i.label}</span>
              ))}
          </p>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
