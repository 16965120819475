import _axios from 'axios';
const {localStorage} = window;
class Axios {
  constructor() {
    this.service = _axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/chat`,
      withCredentials: true,
    });
    this.service.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    this.service.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  async get(path) {
    try {
      const {data} = await this.service.get(path);
      return data;
    } catch (error) {
      return error;
    }
  }

  async post(chatbotId, payload = {}, userId, lang = 'en') {
    const {data} = await this.service.post(`${chatbotId}/${lang}/${userId}`, payload);
    return data;
  }

  async put(path, payload) {
    try {
      const {data} = await this.service.put(path, payload);
      return data;
    } catch (error) {
      return error;
    }
  }
  _handleErrors(error) {
    const {status} = error.response;
    switch (status) {
      case 422: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 404: {
        return {
          status,
          message: error.message,
          error: {},
        };
      }
      case 409: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 403: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 401: {
        return {
          status,
          ...error.response?.data,
        };
      }
      default: {
        return {
          status,
          ...error.response?.data,
        };
      }
    }
  }

  _getChatId() {
    if (localStorage) {
      const chatId = localStorage.getItem('id');
      if (chatId) {
        return chatId;
      } else {
        return null;
      }
    }
  }
}
export const axios = new Axios();
